console.log(
  "process env REACT_APP_DEV_REMOTE",
  process.env.REACT_APP_DEV_REMOTE
)

export const API_BASE_API_URL =
  process.env.NODE_ENV == "production" ||
  process.env.REACT_APP_DEV_REMOTE == "remote"
    ? "https://pmdindia.in/api/api/"
    : "https://pmdindia.in/api/api/"
    // : "http://192.168.0.3:8001/api/"

export const API_BASE_URL =
  process.env.NODE_ENV == "production" ||
  process.env.REACT_APP_DEV_REMOTE == "remote"
    ? "https://pmdindia.in/api/u/serverImg"
    : "https://pmdindia.in/api/u/serverImg"

// export const API_BASE_URL = "https://wild-puce-reindeer-sari.cyclic.app/api/";
export const BASE_URL = "https://pmdindia.in/";
export const ACCESS_TOKEN_NAME = "x-auth-token"
